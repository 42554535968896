<template>
  <div class="material-content">
    <van-icon name="arrow-left"
             @click="go()"
    ></van-icon>
    <navHeader :headObj="{title:'合同查看',desc:'Application Documents'}" type="material"></navHeader>
        <div v-for="index in contrackList" :key="index.termNum">
      <van-cell-group>
      <van-cell  :title="index.title">
        <template>
            <van-button type="primary" size="mini" @click="showCon(index)">点击查看</van-button>
        </template>
      </van-cell>
     </van-cell-group>
    </div>
    <div >

    </div>
    
  </div>
</template>

<script>
import {showContract} from '@/api/index';
import navHeader from "@/components/nav-header"
import { ImagePreview,Collapse, CollapseItem  } from 'vant';
// import {mapActions, mapState} from 'vuex'
export default {
  components: { navHeader },
  data() {
    return {
      contrackList: [],
      activeNames: ['1'],
      pdfList:[],
      phonList:[]

    }
  },
  // computed: {
  //   ...mapState(["userStatus"]),
  // },
  // onShow() {
  //   this.getApplyTable()
  // },

  created(){
       this.showContrack();
  },
  methods: {
    async showContrack() {
      showContract().then((res)=>{
        this.contrackList=res.data[0].files;
        this.contrackList.map((item)=>{
            if(item.fileType==5019){
              item.files.map((index)=>{
          this.phonList.push("https://songcw-sit.oss-cn-shanghai.aliyuncs.com/"+index.fileId)
        })
            }
          
        })
      })
      console.log("phonList",this.phonList)
      console.log("pdfList",this.pdfList)
    },
    async showCon(index){
      if(index.fileType==5019){
        ImagePreview({
            images: this.phonList, // 预览图片的那个数组
            closeable: true,
          })
      }else{
        window.open("https://songcw-sit.oss-cn-shanghai.aliyuncs.com/"+index.files[0].fileId)
      }
    },
    // ...mapActions(["getUserInfo"]),

    // getApplyTable() {
    //   uni.showLoading()
    //   getApplyTable().then(
    //     (res) => {
    //       uni.hideLoading()
    //       this.materialList = res.data
    //       console.log("res :>> ", res)
    //     },
    //     (res) => {
    //     //   this.showMsg(e.message || e.msg)
    //     this.materialList = res.data
    //       console.log("res :>> ", res)
    //       uni.hideLoading()
    //     }
    //   )
    // },
    // go(item) {
    //     let url= JSON.stringify(item.files)
    //   uni.navigateTo({
    //     url: `/pages/material/photo?fileType=${item.fileType}&title=${item.title}&url=${
    //         url
    //     }`,
    //   })
    // },
    // save() {
    //   for (const key in this.materialList) {
    //     const element = this.materialList[key]
    //     if (element.count == 0 && element.isNeed == "1") {
    //       this.showMsg("请上传" + element.materialName)
    //       return
    //     }
    //   }

  
    //   this.goBack()
    // },
    // tipsPhone() {
    //   uni.showModal({
    //     showCancel: false,
    //     content:
    //       "这里可以向审核人员备注，以什么名义与联系人进行电核沟通，比如ETC，信用卡等，以便保护您的隐私",
    //   })
    // },

    go(){
      this.$router.replace('/index');
    },
  },
}
</script>

<style lang="scss" scoped>

page {
  background: #f5f7f9;
}

.van-cell {
  width: 102%;
  font-size: 13px;
}
.m_l_16 {
    margin-left: 12.267vw;
}

.step-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -260px;
  padding: 0 80px 30px 80px;
  .step {
    font-size: 28px;
    opacity: 0.48;
    min-width: 120px;
    .round {
      width: 48px;
      height: 48px;
      line-height: 48px;
      background: #FFFFFF;
      border-radius: 50%;
      color: #2396F5;
      text-align: center;
      margin: auto;
    }
    .txt {
      margin-top: 16px;
      color: #fff;
      text-align: center;
    }
  }
  .hr {
    opacity: 0.4;
    width: 330px;
    height: 50px;
    border-top: 4px dashed #fff;
  }
  .active {
    opacity: 1;
    .round {
      width: 60px;
      height: 60px;
      line-height: 64px;
      border: 8px solid #a6cbff;
    }
  }
}

.material-content {
   .search-icon {

    font-size: 16px;
    line-height: inherit;


  }

  .van-icon {

    font-size: 28PX;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
  .bottom-btn {
    background: #ffffff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;

    box-sizing: border-box;
}
  .banner {
    height: 200px;
    background: url("../../static/images/bank-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 16px 24px;
    box-sizing: border-box;

    .bottom {
      color: #fff;
      text-align: left;
      margin-top: 30px;

      .tips {
        font-size: 40px;
        margin-bottom: 10px;
      }

      .info {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .clause {
    font-size: 24px;
    margin-bottom: 16px;

    .clause-info {
      color: #999;
      align-items: center;
      font-size: small;

      .info {
        color: #2b8eff;
      }
    }
  }



    .remarks {
      flex: 0 0 80%;
      font-size: 28px;
      text-align: right;
      align-items: center;
      justify-content: flex-end;


      button {
        background: #2b8eff;
        height: 26px;
        line-height: 26px;
        font-size: 14px;
        margin-left: 20px;
        margin-right: 0;
        width:100px;

        &.disabled {
          background: #8fc3ff;
        }
      }

      .code {
        font-size: 18px;
        color: #3b4144;
      }

      &.phone {
        flex: 0 0 50%;
      }
    }


  .container {
    padding: 0 24px;
    padding-bottom: 160px;
  }
  .mp260 {
    margin-top: -260px;
  }

  .popup-down-wrapper {
    max-height: 80vh;
    overflow-y: scroll;
    padding: 0px 12px 0px 12px;

    .popup-down {
      display:flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 30px 30px 0px 0px;
      align-items: stretch;
      background-color: #FFFFFF;
      padding: 0px 23px;

      .popup-down-header {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #eee;

        .header-icon {
          margin-top: 12px;
          width: 71px;
          height: 6px;
          background: #e5e5e5;
          border-radius: 3px;
        }

        .header-title {
          display:flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 43px 0px;
          font-size: 18px;
          font-weight: 800;
          color: #313131;

          .left-image {
            float: left;
            width: 24px;
            height: 40px;
            transform:rotate(180deg);
          }

          .title {
            text-align: center;
            flex: 1;
          }
        }
      }

      .popup-down-content {
        max-height: 372px;

        .card-item {
          display:flex;
          align-items: center;
          justify-content: space-between;
          padding: 45px 44px;
          font-size: 26px;
          color: #313131;
          border-bottom: 1px solid #eee;

          .l {
            text {
              margin-left: 11px;
            }
          }
        }
      }

      &.popup-down-select {
        .popup-down-footer {
          font-size: 16px;
          font-weight: bold;
          padding: 45px 44px;
          text-align: center;
          color: #3984ff;
        }
      }

      &.popup-down-add {
        .popup-down-footer {
          .btn-add {
            margin: 31px 0px 59px 0px;
            font-size: 30px;
            color: #FFFFFF;
            background-color: #3984FF;
          }
        }

        .popup-down-add-content{
          .row{
            display:flex;
            justify-content: center;
            align-items: center;
            padding: 55px;
            border-bottom: 1px solid #eee;
            .label{
              font-size:26px;
              color:#313131;
            }
            .r{
              flex: 1;
              text-align: right;
              margin-left: 20px;

              .input-placeholder{
                font-size:26px;
                color:#959595;
              }
            }

            &.code{
              .r{
                display:flex;
                justify-content: center;
                align-items: center;
                input{
                  flex: 1;
                  margin-right: 10px;
                }
                button{
                  padding: 10px 23px;
                  font-size:26px;
                  color:#3984FF;
                  background:#FFFFFF;
                  border:1px solid #9FC3FF;
                  border-radius:4px;
                  line-height: unset;

                  &.disabled{
                    pointer-events: none;
                    color: #959595;
                  }
                }
              }
            }
          }
        }
      }

    }
  }

  .agree-view {
    margin: 30px 0px 0px 0px;
    display: flex;
    align-items: center;
    .agreexyView {
      padding: 14px;
    }
    .agree-image {
      width: 25px;
      height: 25px;
    }

    .xx-textview {
      font-size: 24px;
    }
  }

  .tips-iphone {
    position: absolute;
    left: 162px;
    top: 30px;
    width: 36px;
    height: 36px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADK0lEQVRYR8VXTUhUURT+zlUxFJKCKIIWtaigiKAgG2hmzlOzKGgRSRGu+qEgqBZtapEuyk0QtaiNgrQwaBlGlLx3X1oaEW2CoAgSEkLcVBDB4Hjiyszwxt59746z8MLAzJxzvvOd75577nuEFV60wvlREwHf9w8qpbqIKCMi6wGYj1mzRDQrIu8AvAIQMvNPl+KcCPi+f4qILhPRPhdQADMA7s3Nzd3v6ekpJMUkEhgbG2trbGwcAnDcMXGVGxF9UEr1ZrPZT7Z4K4GJiYktxWJxQkQ2Lid5NKahoWGHjUQsAa31BgA/6k0cjVdK7crlch+XYtoIjAM44EBghoi+i8hqEdlERKsTYqbn5+d3d3V1/Yr6/EcgDMOLIvIgAeiZiIx4njey1CcIgvNEdBXAdkv8IDOfsxIYHR1d09raOgVgmwXgdXNz88FMJvPXRtD3/YxSygewKs5HRDo9zzP2xVWlgO/7fUqpmwnV72fmt2X75OTk2kKhcFpEnjPz1/L/WutBAGficIjoaT6fPxZLQGv9HsAeC4EpZs6Ubb7v9yqlHpV+DzHz2bItDMMTIvLEokChpaVlXXt7++8qBYIg2EdEleqWBi8sLPQ3NTWNZbPZN8amtTaTrq3kN83MmyMKXAFw16akiBzyPO9FFYEwDG+JyHWHzoeIfCGirWVfQ66jo6MvosAjEem1YRHR7Xw+f6OKQNK+pZC6w8zXXOSP4FS2rNKEWutRAEdcFLBVrrU2jWcaMG09Y+ajSxWoiYCIvPQ8rzuy7w8BXEjLXLLHErAeHUs3D3iet9gzWuuTAB47Jjdu/29BLU1YSsTMHJrvQRCMENEpVwKxTZh2DGPAF5NHVt6VQOwxLEmZNIiq8M3Ri/6RMkErriISP4iMh8MorgAxc/QEmeq1iwKJo9jhMqqbQOJlZNAdruNFEstUIPk6jpxp1wcSF9XLPm4PJKVmXNlHMkNifHx8XbFYDADsrKXMGN/PSqnDuVzuWxxO6nuB1tqMVzNml7OGC4XCpe7u7j+24FQCpS3ZC2AAQKcji2kA/cw8nObvRKAMsmKvZmlV1GOvSYF6Etli/wF22mQwqBwp7gAAAABJRU5ErkJggg==") no-repeat;
    background-size: 100%;
  }

  .gzh-img {
    display: block;
    width: 654px;
    height: 874px;
  }

  .pdf-box {
    position: fixed;
    top: 80px;
    bottom: 0;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    z-index: 11;
    .btn {
      margin: 10px 15px;
    }
  }

}
.zifang-min{
    display: flex;
    align-items: center;
    margin-top: 10px;
    uni-button{
        font-size: 12px;
        width: 80px;
        line-height: 1.4em;
        padding: 5px 0;
        display: block;
        border: 1px solid #007aff;
    }
}
.zifang-pop{
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    span{
        font-size: 14px;
    }
    uni-input{
        border: 1px solid #e8e8e8;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 12px;
    }
    .uni-input-placeholder{
        font-size: 12px;
    }
}
.button-zi{
    display: block;
    margin: 5px;
    text-align: center;
    font-size: 14px;
    color: #2b8eff;
    margin-top: 10px;
}
</style>
